// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAnV3jIL2vzGThjxjprDuPT5kL2CT54DUs",
//   authDomain: "epot-yec.firebaseapp.com",
//   projectId: "epot-yec",
//   storageBucket: "epot-yec.appspot.com",
//   messagingSenderId: "610489312373",
//   appId: "1:610489312373:web:5c7f48d226a629896e043c",
//   measurementId: "G-S0QM0Z5PSL"
// };

// Initialize Firebase
// const firebase = initializeApp(firebaseConfig); 

import * as firebase from 'firebase/app';

// import "firebase/messaging";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnV3jIL2vzGThjxjprDuPT5kL2CT54DUs",
  authDomain: "epot-yec.firebaseapp.com",
  projectId: "epot-yec",
  storageBucket: "epot-yec.appspot.com",
  messagingSenderId: "610489312373",
  appId: "1:610489312373:web:5c7f48d226a629896e043c",
  measurementId: "G-S0QM0Z5PSL"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
}

// const messaging = firebase.messaging()

// messaging.getToken({vapidKey: "BNWltUqTNVGNk7P6CvDQiBrDOYJZmft-RwZzmHr7V1qE6fBEFNrRdBq8dKPHRwpf9Nco_JkuyZTBoPX24CehyUA"})

export default firebase

