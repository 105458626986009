<template>
  <v-col
    class="d-flex pa-4"
    :class="`elevation-${elevation} ${color} ${radius}`"
  >
    <!-- class="pa-2" -->
    <v-btn
      :loading="loading"
      v-if="!playing && controller"
      class="my-auto pa-6"
      icon
      @click.stop="play"
      @mousedown.stop
      small
    >
      <v-icon>$play</v-icon>
    </v-btn>
    <v-btn
      v-if="playing && controller"
      class="my-auto pa-6"
      icon
      @click.stop="pause"
      @mousedown.stop
      small
    >
      <v-icon>$pause</v-icon>
    </v-btn>
    <div class="col px-0 pb-0">
      <v-slider
        v-if="controller"
        class="mb-2"
        hide-details="auto"
        :color="playing ? active : inactive"
        track-color="color lighten-5"
        height="10px"
        min="0"
        :max="duration"
        v-model="current"
        @mousedown="seek"
        @change="seeked"
        @mousedown.stop
        @click.stop
        step="0.01"
      ></v-slider>
      <v-progress-linear
        :color="playing ? active : inactive"
        rounded
        background-color="color lighten-5"
        v-else
        :value="(current / duration) * 100"
      ></v-progress-linear>

      <div class="d-flex">
        <div class="caption">{{ getTime(current) }}</div>
        <v-spacer></v-spacer>
        <div class="caption">{{ getTime(duration) }}</div>
      </div>
    </div>
    <slot name="append"></slot>
  </v-col>
</template>

<script>
export default {
  name: "audio-player",
  props: {
    src: { type: String, required: true },
    controller: { type: Boolean, default: true },
    color: { type: String, default: "white" },
    rounded: { type: String, default: "md" },
    elevation: { type: String, default: "1" },
    active: { type: String, default: "primary" },
    inactive: { type: String, default: "color" },
    playlist: { type: String, default: null },
    autoplay: { type: Boolean, default: false },
  },
  computed: {
    radius() {
      let rounded = "rounded";

      if (this.rounded !== "md") {
        rounded += `-${this.rounded}`;
      }

      return rounded;
    },
  },
  data() {
    return {
      loading: false,
      playing: null,
      paused: null,
      seeking: null,
      current: 0,
      duration: 0,
      player: null,
    };
  },
  created() {
    this.player = new Audio(this.src);

    this.player.onloadstart = () => {
      this.loading = true;
    };
    this.player.onloadeddata = () => {
      this.playing = false;
      this.paused = true;
      this.seeking = false;
      this.current = 0.0;
      this.duration = this.player.duration;
      this.loading = false;
      this.play();
    };
    this.player.onended = () => {
      this.playing = false;
    };
    this.player.onseek = () => {
      this.seeking = true;
    };
    this.player.onwaiting = () => {
      this.$emit("waiting");
    };
    this.player.onplaying = () => {
      this.$emit("playing");
    };
    this.player.onplay = () => {
      this.$emit("play");
    };
    this.player.onseeked = this.seeked();
  },
  beforeDestroy() {
    this.player.pause();
  },
  methods: {
    play() {
      this.$emit("play");
      let vm = this;

      vm.player.onplay = function () {
        vm.playing = true;
        vm.paused = false;
        if (Math.abs(vm.current - vm.player.duration) == 0) vm.current = 0.0;
        vm.player.currentTime = vm.current;
      };
      vm.player.ontimeupdate = function () {
        if (!vm.seeking) {
          vm.current = vm.player.currentTime;
        }
      };
      vm.player.play();
    },
    pause() {
      let vm = this;

      vm.player.onpause = function () {
        vm.playing = false;
        vm.paused = true;
        vm.player.ontimeupdate = null;
      };
      vm.player.pause();
    },
    seek() {
      this.seeking = true;
    },
    seeked(end = 0.0) {
      this.player.currentTime = end;
      this.seeking = false;
    },

    getTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      const minutes = (seconds % 3600) / 60;

      return [minutes, seconds % 60].map(format).join(":");
    },
  },
  watch: {
    src: function () {
      this.player.src = this.src;
    },
  },
};
</script>