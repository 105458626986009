<template>
  <guest v-if="!$store.getters.isLoggedIn || $route.path == '/sso'" />
  <auth v-else-if="$store.getters.isLoggedIn" />
</template>

<script>
import "./assets/style.css";
import "./styles/variables.scss";
export default {
  metaInfo: {
    title: "LMS",
  },
  components: {
    auth: require("./views/template/Auth").default,
    guest: require("./views/template/Guest").default,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap");
$z: 24;
@while $z >= 0 {
  .v-application .flat-b-#{$z} {
    box-shadow: 0 #{$z * 2}px 0 rgba($color: #000000, $alpha: 0.1) !important;
  }

  .v-application .flat-l-#{$z} {
    box-shadow: #{$z * 2}px 0 0 rgba($color: #000000, $alpha: 0.1) !important;
  }

  .v-application .flat-d-#{$z} {
    box-shadow: #{$z *
      2}px
      #{$z *
      2}px
      0
      rgba($color: #000000, $alpha: 0.1) !important;
  }

  $z: $z - 1;
}

html {
  overflow: auto;
  font-family: "Poppins";
}
.theme--light.v-btn,
.theme--light.v-btn.v-btn--icon,
.theme--light.v-icon {
  color: var(--v-color-base);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--light.v-application,
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle,
.theme--light.v-sheet {
  color: var(--v-color-base) !important;
}
.v-input--checkbox .v-icon {
  color: #9cafc6;
}
.theme--light.v-application {
  background-color: var(--v-background-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-up {
  from {
    width: 100%;
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    left: 0;
    right: 0;
  }
  to {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-enter-active {
  animation: fade 0.3s;
}
.fade-up-leave-active {
  animation: fade-up 0.3s reverse;
}
.fade-up-enter-active {
  animation: fade-up 0.3s;
}
a {
  text-decoration: none;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) .v-badge__badge {
  color: #fff !important;
  background-color: #9cafc6 !important;
}
.color-navy {
  color: #4c6583 !important;
}
.color-navy-soft {
  color: #9cafc6 !important;
}
.accent-warning {
  color: #be9f05 !important;
  border: 1px solid #be9f05 !important;
  background: transparent !important;
}
.accent-success {
  color: #4da41d !important;
  border: 1px solid #4da41d !important;
  background: transparent !important;
}
.accent-default {
  color: #9cafc6 !important;
  border: 1px solid #9cafc6 !important;
  background: transparent !important;
}
</style>