import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.VUE_APP_SENTRY_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});