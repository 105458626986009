import Vue from 'vue'
import Vuex from 'vuex'

import auth from './_auth'
import question from './_question'
import exam from './_exam'
import media from './_media'

Vue.use(Vuex)



export default new Vuex.Store({
  state: {
    dialog: {
      identification_speech: false,
    }
  },
  mutations: {
    SET(state, {
      key,
      value
    }) {
      state[key] = value;
    }
  },
	modules: { 
		auth,question,exam,media
	}
})

// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     exam: require('./_exam').default,
//     question: require('./_question').default,
//     grouped_question: require('./_grouped_question').default,
//     media: require('./_media').default,
//     section: require('./_section').default,
//     auth: require('./_auth').default
//   }
// })

