<template>
  <v-row>
    <v-col>
      <v-row v-if="media && media.type == 'audio'">
        <v-col md="6" lg="4" class="pt-0">
          <v-card outlined>
            <v-card-text class="pa-0">
              <audio-player
                ref="audio"
                elevation="0"
                autoplay
                :src="media.url"
                :controller="false"
              ></audio-player>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="body-1">
        <span v-show="number" class="me-1">{{ number }}. </span> {{ text }}
      </div>
      <v-radio-group hide-details="auto" v-model="answer" class="mt-1">
        <v-radio
          v-for="(option, index) in options"
          :value="option"
          :key="index"
          :label="option.text"
          class="mb-2"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
import AudioPlayer from "../components/AudioPlayer";

export default {
  props: {
    data: { type: Object },
    number: { type: Number },
  },
  components: { AudioPlayer },
  data() {
    return {
      answer: null,
    };
  },
  computed: {
    question() {
      return this.data ? this.data.question : {};
    },
    text() {
      return this.question ? this.question.text || "Mark your answer!" : '';
    },
    options() {
      return this.data ? this.data.options : [];
    },
    media() {
      return this.question.media;
    },
  },
  mounted() {
    if (this.data.answer) {
      this.answer = this.data.answer;
    }
    // this.$emit("update:data", { ...this.data, answer: null });
  },
  watch: {
    answer: function (answer) {
      this.$emit("update:data", { ...this.data, answer });
    },
    "data.id": function () {
      this.answer = null;
      if (this.media && this.media.type == "audio") {
        this.$refs.audio.play();
      }
    },
    "data.answer": function (answer) {
      this.answer = answer;
    },
  },
};
</script>
<style lang="">
</style>