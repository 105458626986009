<template>
  <v-row>
    <v-col>
      <!-- <v-row v-if="media && media.type == 'audio'">
        <v-col md="6" lg="4" class="pt-0">
          <v-card outlined>
            <v-card-text class="pa-0">
              <audio-player
                elevation="0"
                autoplay
                :src="media.url"
              ></audio-player>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <div class="body-1 d-flex">
        <span v-show="number" class="me-1">{{ number }}. </span>
        <p class="fs-18 mb-0"> {{ text }} </p>
      </div>
      <v-radio-group hide-details="auto" v-model="selected_answer" class="mt-1">
        <v-radio
          v-for="(option, index) in options"
          :value="option.text"
          :key="index"
          :label="option.text"
          class="mb-2"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
// import AudioPlayer from "../components/AudioPlayer";

export default {
  name: "epot-question-reading",
  props: {
    data: { type: Object },
    number: { type: Number },
  },
  // components: { AudioPlayer },
  data() {
    return {
      selected_answer: null,
    };
  },
  computed: {
    question() {
      return this.data ? this.data.question : {};
    },
    text() {
      if (this.question) {
        return this.question.text || "Mark your answer!";
      }else{
        return '';
      }
    },
    options() {
      return this.data ? this.data.options : [];
    },
    media() {
      return this.question.media;
    },
  },
  mounted() {
    if (this.data.selected_answer) {
      this.selected_answer = this.data.selected_answer;
    }
    // else{
    //   this.selected_answer= null;
    // }
    // this.$emit("update:data", { ...this.data, selected_answer: null });
  },
  watch: {
    selected_answer: function (selected_answer) {
      this.$emit("update:data", selected_answer);
      // this.$emit("update:data", { ...this.data, selected_answer });
    },
    "data.id": function () {
      // this.selected_answer = null;
    },
    "data.selected_answer": function (selected_answer) {
      // if(!selected_answer){
      //   this.selected_answer= null;
      // }
      this.selected_answer = selected_answer;
    },
  },
};
</script>
<style>
</style>