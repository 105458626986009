import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// axios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIiLCJpYXQiOjE2MDY4Nzc2MTMsImlzcyI6ImVJeEFudHVXT1U2bWZXVGswRVVqOVJIU21sWVY2Vkd5Iiwic3ViIjoieXRjIiwidXNlciI6eyJjb21wYW55X2FjY2Vzc19rZXkiOiJkOTg2YzYzYy0wOTgyLTQ2YzYtOGFlZC0yYjE2ZDcyZTE2MzMiLCJjb21wYW55X2VtYWlsIjoiYWRtaW5AeXRjLmNvbSIsImNvbXBhbnlfaWQiOiI1ZmFkMmZmNmQ5YzE1YTAzZWNlNTI3MmEiLCJlbWFpbCI6ImFkbWluQHl0Yy5jb20iLCJnZW5kZXIiOjEsImlkIjoiNWY4OTY2YTI0M2ZiNDc3YWI3Njc5NDljIiwicm9sZV9pZCI6IjVmYWQxNDg2NDBjYWNlM2Q2MWVjZDc4NyIsInJvbGVfc2x1ZyI6ImFkbWluLWJhbmsiLCJzdGF0dXMiOjEsInVzZXJuYW1lIjoieXRjIn19.uU3iGvsR68jVBDDUcgxyxRXHldNLSeYhszXWFc83TsM'
axios.defaults.headers.common['Content-Type'] = 'application/json' 
axios.defaults.headers['Content-Type'] = 'application/json' 
// axios.defaults.headers.common['X-Company-ID'] = '5fad2ff6d9c15a03ece5272a'

Vue.use(VueAxios, axios)