<template>
  <v-app>
    <v-container fluid class="py-0">
      <transition name="fade">
        <router-view v-if="loaded"></router-view>
      </transition>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  },
  beforeDestroy() {
    this.loaded = false;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
body{
  font-family: "Poppins" !important;
}
</style>