export default { 
  EpotStart: require('./EpotStart').default,
  EpotEnd: require('./EpotEnd').default,
  EpotPreparation: require('./EpotPreparation').default,
  EpotDeviceVerification: require('./EpotDeviceVerification').default,
  EpotCheckCam: require('./EpotCheckCam').default,
  EpotCheckHeadset: require('./EpotCheckHeadset').default,
  EpotListening: require('./EpotListening').default,
  EpotReading: require('./EpotReading').default,
  EpotStructure: require('./EpotStructure').default,
  EpotSpecification: require('./EpotSpecification').default,
  Viewer: require('./Viewer').default,
}