<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto" md="8">
      <v-row>
        <v-col class="white px-12 mx-auto" md="10" style="height: 100vh">
          <v-spacer class="py-10"></v-spacer>
          <v-form class="px-8" @submit.prevent="submit" lazy-validation ref="form" v-model="valid">
            <div class="mb-4">
              <span class="fs-20 font-weight-medium">Masukan email anda dan kami akan mengirimkan link untuk memperbarui password anda</span>
            </div>
              <v-alert type="error" text dense prominent v-show="response !== null">{{ response }}</v-alert>
              <v-alert type="success" text dense prominent v-show="responsesukses !== null">{{ responsesukses }}</v-alert>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-2">
                  <span class="font-weight-medium">Email</span>
                </div>
                <v-text-field
                  class=""
                  v-model="email"
                  placeholder="Enter your email"
                  :rules="emailRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-2">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Kirim OTP ke email
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "password-send-email",
  data() {
    return {
      errors: [],
      valid:true,
      email: "",
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      submiting: false,
      response:null,
      responsesukses:null
    };
  },
  methods: {
    submit() {
      // this.submiting = true;
      this.submiting = true;
      this.axios.post('/users/v1/member/forgot-password', {
        access_key: process.env.VUE_APP_ACCESS_KEY,
        email: this.email
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        // console.log(res);
        if (res.data.status == 200) {
          this.$router.push(`/success-send-email?code=${res.data.data.code}`);
          this.sent = true;
          this.submiting = false;
        }
      }).catch(error => {
        this.submiting = false;
        console.error(error.response);
        if (error.response) {
          let errorCode = error.response.data.status

          if (errorCode == 400) {
            this.response = "Email Anda tidak terdaftar pada sistem kami"
          }
        }
      })
    },
  },
};
</script>

<style>

</style>