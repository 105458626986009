<template>
  <v-row align-content="center">
    <v-col class="py-0 mx-auto my-5" md="12">
      <v-row>
        <v-col class="white px-12 mx-auto rounded-lg" md="10" style="height: 80vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4" class="text-center">
                <img :src="image">
              </v-col>
            </v-row>
            <div class="fs-32 mb-5 text-center font-weight-bold">
              {{ title }}
            </div>
            <div class="fs-18 text-center color-disable">
              <span>{{ message }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "epot-endTes",
  data() {
    return {
      title: "",
      image: "",
      message: ""
    }
  },
  computed: {
    user(){
      return JSON.parse(localStorage.getItem('ytc_lms_auth'));
    },
  },
  mounted(){
    // console.log(this.$route.query);

    if (this.user.is_b2b == 1) {
      if (this.$route.query.type && this.$route.query.type == 'gugur') {
        this.image = require('@/assets/images/gugur_tes.png')
        this.title = "Test telah dihentikan"
        this.message = "Anda telah terindikasi melakukan kecurangan dengan membuka tab baru sebanyak 3x, sesuai dengan syarat dan ketentuan yang berlaku maka tes anda kami hentikan."
      }else{
        this.image = require('@/assets/images/success.png')
        this.title = "Selamat Anda telah menyelesaikan test EPOT"
        this.message = "Beberapa saat lagi anda akan diarahkan kembali menuju halaman beranda anda"
      }
    }else {
      this.image = require('@/assets/images/success.png')
      this.title = "Selamat Anda telah menyelesaikan test EPOT"
      this.message = "Mohon tunggu beberapa saat, Anda akan diarahkan menuju halaman sertifikat"
    }

    setTimeout( () => {
      if(this.user.is_b2b == 1){
        this.$router.push({ path: '/epot'})
      }else{
        this.$router.push({ path: '/reward'})
      }
      window.location.reload(true);
    }
    , 5000);

  },
};
</script>

<style>
</style>