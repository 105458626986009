<template>
  <v-row>
    <v-col>
      <v-card elevation="0">
        <v-card-text>
          <div class="text-center" v-if="loading">
            <v-progress-circular indeterminate></v-progress-circular><br />
            <dir>Redirecting...</dir>
          </div>
          <div v-else-if="error">Something went wrong</div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { decode } from "js-base64";

export default {
  name: "sso-login",
  data: () => ({
    loading: false,
    error: false,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    const salt = 'y1u2r3e4k5a6'

    this.loading = true;
    let email = decode(this.$route.query.uid).replace(salt, '');
    let fullname = decode(this.$route.query.name).replace(salt, '');

    if (!(email && fullname)) {
      this.error = true;
      this.loading = !this.error;
      return;
    }

    setTimeout(() => {
      this.$store
        .dispatch("sso_login", { email, fullname })
        .then(() => {
          if (this.user.is_updated_profile) {
            this.$router.push("/epot");
          } else {
            this.$router.push("/account");
          }
        })
        .catch(() => {
          this.loading = !this.error;
        });
    }, 1000);
  },
};
</script>