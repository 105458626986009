const url = {
  // question
  question_list: '/bank/v1/question',
  question_detail: '/bank/v1/question/detail/{id}',
  question_detail_public: '/bank/v1/question/public/{id}',

  // storage
  // media_list: '/storage/v1/media',
  // media_detail: '/storage/v1/media/detail/{id}',
  // media_detail_public: '/storage/v1/media/public/{id}',
  media_upload: '/storage/v1/media/user/upload',

  // exam / epot
  exam_index: '/exam/v1',
  exam_list: '/exam/v1/list',
  exam_detail: '/exam/v1/detail/{id}',

  // FORNEXT: Hanya untuk keperluan demo
  get_id: () => 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }),
  success: (data) => ({
    status: 200,
    message: 'success',
    data,
    validation: {}
  }),
  error: (validation) => ({
    response: {
      data: {
        status: 400,
        message: 'error',
        data: {},
        validation
      }
    }
  }),
}

export default url