<template>
  <v-row align-content="center" class="white">
    <v-col v-show="regis" class="py-0" md="12">
      <v-row>
        <v-col class="white px-12 mx-auto" md="7" style="height: 100vh">
          <v-spacer class="py-2"></v-spacer>
          <v-form
            class="px-8"
            @submit.prevent="submit"
            ref="form"
            lazy-validation
            v-model="valid"
          >
            <div class="fs-24 font-weight-bold mb-2">Welcome back to YEC</div>
            <div class="color-disable mb-2">
              Sudah mempunyai akun ?
              <router-link
                class="text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'login' }"
              >
                <span>Masuk ke akun anda</span>
              </router-link>
            </div>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-2">
                  <span class="font-weight-medium">Email</span>
                </div>
                <v-text-field
                  class=""
                  v-model="email"
                  placeholder="Masukkan email anda"
                  :rules="emailRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-2">
                  <span class="font-weight-medium">Password</span>
                </div>
                <v-text-field
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  v-model="password"
                  placeholder="Min. 8 karakter"
                  :rules="pswRules"
                  outlined
                  hide-details="auto"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-0">
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-medium">Konfirmasi Password</span>
                </div>
                <v-text-field
                  :type="showConfirm ? 'text' : 'password'"
                  :append-icon="showConfirm ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="showConfirm = !showConfirm"
                  v-model="confirm_password"
                  placeholder="Masukkan ulang password anda"
                  :rules="cnfrmPswRules"
                  outlined
                  hide-details="auto"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Daftar
                </v-btn>
              </v-col>
              <v-col cols="12" align="center" class="py-0">
                <div id="line">
                  <span class="color-disable font-weight-regular"
                    >Atau daftar dengan</span
                  >
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="" md="12">
                    <v-btn
                      large
                      elevation="0"
                      @click="loginWithGoogle()"
                      outlined
                      block
                      class="font-weight-medium"
                    >
                      <img src="@/assets/images/google.png" class="mr-1" />
                      Google
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols="" md="12">
                    <v-btn
                      large
                      elevation="0"
                      @click="loginWithFacebook()"
                      outlined
                      block
                      class="font-weight-medium"
                    >
                      <img src="@/assets/images/facebook.png" class="mr-1" />
                      Facebook
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-show="verify" class="py-0 mx-auto" md="8">
      <v-row>
        <v-col class="white px-12 mx-auto" md="10" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4" class="text-center">
                <img src="@/assets/images/verify.png" />
              </v-col>
            </v-row>
            <div class="fs-32 mb-5 text-center font-weight-bold">
              Harap verifikasi email anda
            </div>
            <div class="fs-18 text-center color-disable">
              <span
                >Kami telah mengirimkan link untuk memverifikasi akun anda,
                segera periksa kotak masuk email anda kemudian verifikasi email
                anda</span
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "registration",
  data() {
    return {
      errors: [],
      show: false,
      showConfirm: false,
      regis: true,
      verify: false,
      valid: true,
      email: "",
      password: "",
      confirm_password: "",
      emailRules: [
        (v) => !!v || "E-mail tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "E-mail harus valid",
      ],
      pswRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      submiting: false,
    };
  },
  computed: {
    cnfrmPswRules() {
      return [
        () =>
          this.password === this.confirm_password ||
          "Konfirmasi password harus sama",
        (v) => !!v || "Konfirmasi Password tidak boleh kosong",
      ];
    },
    ...mapGetters(["user"]),
  },
  methods: {
    async loginWithFacebook() {
      var provider = new firebase.auth.FacebookAuthProvider();

      firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        console.log('Credential : ', credential);

        // const additionalUserInfo = result.additionalUserInfo;
        // console.log('additionalUserInfo : ', additionalUserInfo);

        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = credential.accessToken;
        // var IDtoken = credential.idToken;
        // console.log('Token : ', token);
        // console.log('IDtoken : ', IDtoken);
        // The signed-in user info.
        // var user = result.user;
        // console.log('user : ', user);

        // this.LoginSSO(additionalUserInfo, IDtoken)

        // setTimeout(() => {
        //   firebase.auth().signOut().then(() => {
        //     // Sign-out successful.
        //   }).catch((error) => {
        //     // An error happened.
        //   });
        // }, 10000)
        // ...
      }).catch((error) => {
        this.$store.state.overlay.state = false
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('errorCode : ', errorCode);
        console.error('errorMessage : ', errorMessage);
        // The email of the user's account used.
        var email = error.email;
        console.error('email : ', email);
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.error('credential : ', credential);
        // ...
      });

      // window.FB.login((response) => {
      //   if (response.status == "connected") {
      //     this.$store
      //       .dispatch("login_facebook", response.authResponse.accessToken)
      //       .then(() => {
      //         this.submiting = false;
      //         if (localStorage.getItem("ytc_lms_redirect") != null) {
      //           this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //           localStorage.removeItem("ytc_lms_redirect");
      //         } else {
      //           if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //           // this.$router.push("/dashboard");
      //         }
      //       })
      //       .catch((error) => {
      //         this.submiting = false;
      //         // this.$refs.form.reset();
      //         console.error(error);
      //         this.response = error;
      //       });
      //   }
      // });
    },
    async loginWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();

      firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        // console.log('Credential : ', credential);

        const additionalUserInfo = result.additionalUserInfo;
        // console.log('additionalUserInfo : ', additionalUserInfo);

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        var IDtoken = credential.idToken;
        // console.log('Token : ', token);
        // console.log('IDtoken : ', IDtoken);
        // The signed-in user info.
        // var user = result.user;
        // console.log('user : ', user);

        this.LoginSSO(additionalUserInfo, IDtoken, token)
        // ...
      }).catch((error) => {
        this.$store.state.overlay.state = false
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error('errorCode : ', errorCode);
        console.error('errorMessage : ', errorMessage);
        // The email of the user's account used.
        var email = error.email;
        console.error('email : ', email);
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.error('credential : ', credential);
        // ...
      });

      // const googleUser = await this.$gAuth.signIn();
      // // console.log('user',googleUser)
      // this.$store
      //   .dispatch("login_google", googleUser.wc.id_token)
      //   .then(() => {
      //     this.submiting = false;
      //     if (localStorage.getItem("ytc_lms_redirect") != null) {
      //       this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //       localStorage.removeItem("ytc_lms_redirect");
      //     } else {
      //       if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //       // this.$router.push("/dashboard");
      //     }
      //   })
      //   .catch((error) => {
      //     this.submiting = false;
      //     console.error('why',error);
      //     this.response = error;
      // });

    },

    async LoginSSO(user, IDtoken){
      // console.log(user);
      // console.log(IDtoken);
      // console.clear()
      this.$store
        .dispatch("login_google", IDtoken)
        .then(() => {
          this.submiting = false;
          if (localStorage.getItem("ytc_lms_redirect") != null) {
            this.$router.push(localStorage.getItem("ytc_lms_redirect"));
            localStorage.removeItem("ytc_lms_redirect");
          } else {
            if(this.user.is_updated_profile){
                  window.location.reload(true);
                  this.$router.push("/epot");
                } else {
                  // window.location.reload(true);
                  this.$router.push("/account");

                }
            // this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.submiting = false;
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            // let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.response = errorData.message;
            }else{
              this.response = errorData.message;
            }
          }else{
            this.$store.state.errorNetwork = true
            this.process.run = false
            this.process.form = false
          }
          // console.error('why',error);
          // this.response = error;
      });
    },
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let access_key = process.env.VUE_APP_ACCESS_KEY;
        let email = this.email;
        let new_password = this.password;
        let password = this.password;
        let conf_new_password = this.confirm_password;

        this.axios
          .post(
            `/users/v1/member/register/`,
            { access_key, email, new_password, conf_new_password },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // let res = response.data;
            if (response.status == 200) {
              // this.verify = true;
              // this.regis = false;
              this.$store
              .dispatch("login", { email, password })
              .then(() => {
                this.submiting = false;
                if (localStorage.getItem("ytc_lms_redirect") != null) {
                  this.$router.push(localStorage.getItem("ytc_lms_redirect"));
                  localStorage.removeItem("ytc_lms_redirect");
                } 
                else {
                    if(this.user.is_updated_profile){
                      window.location.reload(true);
                      this.$router.push("/epot");
                    } else {
                      // window.location.reload(true);
                      this.$router.push("/account");

                    }
                }
              })
              // console.log("result", res);
              // setTimeout( () => {
              //   this.$router.push({ name: 'login'})
              // }
              // , 5000);
            }
          }).catch((error) => {
            this.submiting = false;
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              // let errorHeader = error.response.headers;

              if (errorCode === 401) {
                this.response = errorData.message;
              }else{
                this.response = errorData.message;
              }
            }else{
              this.$store.state.errorNetwork = true
              this.process.run = false
              this.process.form = false
            }
          });
      } else {
        this.submiting = false;
      }
    },
  },
};
</script>

<style>
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>