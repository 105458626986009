<template>
  <v-card elevation="0" class="transparent px-2 py-2">
    <div class="fs-24 color-state font-weight-bold mb-2"><span>Panduan EPOT</span></div>
    <v-card-text class="px-0">
      <v-card class="rounded-lg text-center" elevation="0">
        <div class="col-md-10 mx-auto">
          <!-- <p class="font-weight-bold fs-20">CATATAN PENTING!</p>
          <p class="font-weight-medium fs-18">Ketentuan</p> -->
          <p class="font-weight-bold fs-20">Panduan Tes English Proficiency Online Test</p>
          <div class="fs-18 text-center color-disable mb-2">
            <span>Tes teruji berjalan stabil di browser Google Chrome. Kami sangat merekomendasikan mengakses tes menggunakan browser Google Chrome. Tes TIDAK BISA BERJALAN di browser selain Google Chrome.</span>
          </div>
          <!-- <p class="font-weight-medium fs-18">Tes TIDAK BISA BERJALAN di browser selain Google Chrome</p> -->
          <div class="fs-18 text-center color-disable mb-2">
            <span>Setelah melakukan pembelian dan pembayaran, berikut adalah langkah untuk mengakses English Proficiency Online Test</span>
          </div>
        </div>
        <div class="col-md-8 mx-auto">
          <ol class="color-disable text-left">
            <!-- <li class="mb-2">
              <p>Login menggunakan email dan password yang Anda masukkan saat melakukan pembelian.Login melalui link https://www.yec.co.id/masuk</p>
              <img src="@/assets/images/login.png">
            </li> -->
            <li class="mb-2">
              <p>Pada menu Account, Anda diwajibkan mengisi data diri untuk keperluan identitas sertifikat. Data diri hanya dapat disubmit 1 kali dan tidak dapat diubah.</p>
              <img src="@/assets/images/account.png">
            </li>
            <li class="mb-2">
              <p>Setelah mengisi data diri, pilih menu Test EPOT lalu masukkan kode lisensi Anda dan klik tombol redeem lisensi seperti berikut</p>
              <img src="@/assets/images/lisensi.png">
            </li>
            <li class="mb-2">
              <p>English Proficiency Online Test hanya bisa dilakukan sekali. Pastikan saat klik tombol mulai tes, Anda sudah siap.</p>
              <img src="@/assets/images/list.png">
            </li>
            <li class="mb-2">
              <p class="mb-0">Persiapan sebelum melakukan tes</p>
              <ul>
                <li>Durasi tes adalah 115 menit / 2 jam kurang 5 menit. Tes terbagi ke tiga tahap</li>
                <li>Pastikan laptop / komputer sudah tersambung listrik dan tidak dalam posisi low battery</li>
                <li>Rekomendasi kami adalah menggunakan Laptop dengan baterai yang sehat agar jika terjadi listrik mati, Anda tetap bisa melakukan tes</li>
                <li>Pastikan koneksi internet lancar</li>
                <li>Pastikan Anda berpakaian rapi karena selama tes berlangsung kamera akan terus menyala</li>
                <li>Gunakan headset agar kualitas audio terdengar jelas untuk sesi listening</li>
                <li class="mb-2">Berikan pesan kepada anggota keluarga, teman atau saudara yang tinggal serumah bahwa Anda tidak bisa diganggu selama durasi tes</li>
              </ul>
            </li>
            <li class="mb-2" v-show="user.institusion.id == ''">
              <p>Sertifikat akan muncul otomatis pada menu Sertifikat setelah Anda menyelesaikan keseluruhan tes.</p>
              <!-- <p>Skor Hasil Tes akan muncul otomatis setelah Anda menyelesaikan tiga tahap tes pada menu Sertifikat.</p> -->
            </li>
            <!-- <li class="mb-2">
              <p>Sertifikat digital akan terkirim ke email Anda. Silakan download dan gunakan untuk untuk keperluan Anda</p>
            </li> -->
            <li class="mb-2" v-show="user.institusion.id == ''">
              <p>Untuk pembelian produk English Proficiency Online test setelah Anda menyelesaikan tes, sertifikat cetak akan kami kirim H+1 pada hari Senin - Jumat jam 08.00 - 15.00 (Sabtu - Minggu dan tanggal merah libur) </p>
            </li>
          </ol>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>


export default {
  name: "guide-view",
  data(){
    return {

    }
  },
  created() {
    
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("ytc_lms_auth"))
    }
  },
  methods: {
  },
};
</script>

<style>

</style>