<template>
  <v-row align-content="center" class="white">
    <v-col class="py-0 mx-auto" md="8">
      <v-row>
        <v-col class="white px-12 mx-auto" md="10" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-4" justify="center">
              <v-col sm="6" lg="4">
                <img src="@/assets/images/success.png">
              </v-col>
            </v-row>
            <div class="fs-32 mb-5 text-center font-weight-bold">
              Password berhasil diperbarui
            </div>
            <div class="fs-18 mb-4 text-center color-disable">
              <span>Perubahan password telah berhasil dilakukan, sekarang anda dapat kembali masuk untuk mengakses akun anda</span>
            </div>
            <!-- <v-row>
              <v-col>
                <v-btn
                  large
                  elevation="0"
                  color="primary"
                  :loading="submiting"
                  block
                  @click="goLogin()"
                  class=""
                >
                  Masuk ke akun anda
                </v-btn>
              </v-col>
            </v-row> -->            
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "success-reset-password",
  mounted(){
    setTimeout( () => {
        this.$router.push({ name: 'login'})
      }
      , 5000);
  },
  methods:{
  }
};
</script>

<style>
</style>