<template>
  <v-row>
    <v-col>
      <div v-if="media && media.type == 'image'">        
        <div class="body-1 mb-3">
          <div style="display: flex;">
            <span v-show="number">{{ number }}. </span>
            <div class="">
              <img :src="media.url" class="img-question-structure">
            </div>          
          </div>
            <!-- <img src="https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/images/1611137255712255539-reading1-1.png" class="img-question"> -->        
        </div>
        <p class="fs-18 mb-0">{{ text }}</p>
      </div>
      <div v-else>
        <div style="display: flex;">
          <span v-show="number" class="body-1 me-1">{{ number }}. </span>
          <p class="fs-18 mb-0">{{ text }}</p>
        </div>
      </div>
      <v-radio-group hide-details="auto" v-model="selected_answer" class="mt-1">
        <v-radio
          v-for="(option, index) in options"
          :value="option.text"
          :key="index"
          :label="option.text"
          class="mb-2"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
// import AudioPlayer from "../components/AudioPlayer";

export default {
  name: "epot-question-structure",
  props: {
    data: { type: Object },
    number: { type: Number },
  },
  // components: { AudioPlayer },
  data() {
    return {
      selected_answer: null,
    };
  },
  computed: {
    question() {
      return this.data ? this.data.question : {};
    },
    text() {
      if (this.question) {
        return this.question.text || "Mark your answer!";
      }else{
        return '';
      }
    },
    options() {
      return this.data ? this.data.options : [];
    },
    media() {
      return this.question.media;
    },
  },
  mounted() {
    if (this.data.selected_answer) {
      this.selected_answer = this.data.selected_answer;
    }else{
      this.selected_answer= null;
    }
    // this.$emit("update:data", { ...this.data, selected_answer: null });
  },
  watch: {
    selected_answer: function (selected_answer) {
      this.$emit("update:data", { ...this.data, selected_answer });
    },
    "data.id": function () {
      this.selected_answer = null;
    },
    "data.selected_answer": function (selected_answer) {
      this.selected_answer = selected_answer;
    },
  },
};
</script>
<style lang="">
</style>